import React from "react";
import * as styles from "./container.module.css";

const Container = ({ children, maxWidth = "1140px", className }) => {
  return (
    <div
      style={{
        maxWidth,
      }}
      className={styles.container + " " + (className ? className : "")}
    >
      {children}
    </div>
  );
};

export default Container;
