import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/program-do-tworzenia-ofert-handlowych.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";



export const query = graphql`
  {
    panel: file(relativePath: { eq: "panel-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    notatka: file(relativePath: { eq: "klient-zdarzenie-notatka.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        finanse: file(relativePath: { eq: "karta-klienta-finanse.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
            atrybuty: file(relativePath: { eq: "karta-klienta-atrybuty.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
              karta: file(relativePath: { eq: "karta-klienta-zadania-i-zlecenia.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
              kalendarz: file(relativePath: { eq: "kalendarz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
                pulpit: file(relativePath: { eq: "moj-pulpit.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    dokument: file(relativePath: { eq: "dokument-ofertowy-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    ofertowy: file(relativePath: { eq: "dokument-ofertowy-crm-cd.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    szansa: file(relativePath: { eq: "pojedyncza-szansa-sprzedazy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    kanban: file(relativePath: { eq: "kanban-szansa-sprzedazy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    lista: file(relativePath: { eq: "szansa-sprzedazy-lista.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    komunikator: file(relativePath: { eq: "komunikator.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    mapa: file(relativePath: { eq: "crm-mapa.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const programdotworzeniaoferthandlowych = ({ data }) => {
  return (
    <Artykul
      title="Wykorzystaj program do tworzenia ofert handlowych w celu poprawy swoich działań sprzedażowych"
      articleImage={obrazekArtykulu}
      articleImageAlt="Wykorzystaj program do tworzenia ofert handlowych w celu poprawy swoich działań sprzedażowych"
      metaTitle="Wykorzystaj program do tworzenia ofert handlowych w celu poprawy swoich działań sprzedażowych"
      metaDescription="Jesteś handlowcem, a Twoja firma chce wykorzystywać program do tworzenia ofert handlowych? Opowiemy Ci jak system CRM może usprawnić Twoje działanie."
    >
      <p
        style={{
          paddingTop: 20,
        }}
      >Pracujesz jako przedstawiciel handlowy i chciałbyś poprawić wyniki swojej pracy? 
      Chcesz realizować targety szybciej i efektywniej? <strong>Wykorzystaj program do tworzenia ofert handlowych.</strong></p>
<p>Doświadczony handlowiec, który stosuje w&nbsp;swojej pracy system CRM, 
przedstawi Ci korzyści, które wynikają z&nbsp;jego użytkowania.</p> 
<p>Nasza ekspertka mierzy się na co dzień z tymi samymi problemami, co Ty. 
Doskonale rozumie Twoje potrzeby, problemy i wątpliwości. 
W związku z tym, jej perspektywa może być dla Ciebie bardzo pomocna.</p>
<p>Obejrzyj poniższy vlog lub przeczytaj ten artykuł, a&nbsp;znajdziesz odpowiedź na następujące pytania:</p>
<p><ul><li>Czy system CRM służy do kontroli pracowników?</li>
<li>Czy <strong>program do tworzenia ofert handlowych</strong> może wesprzeć proces sprzedaży?</li>
<li>Jak wykorzystać system CRM do tworzenia ofert?</li>
<li>Dlaczego relacje z klientami są trwalsze dzięki CRM?</li></ul></p>
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="TYql_R1ca_A"
          title="CRM dla handlowców. Jak pomoże Ci w pracy?"
        />
      </div>

      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Czy program do tworzenia ofert handlowych może służyć do kontroli pracowników?</strong>
      </h2>
      <p>Odpowiedź na powyższe pytanie brzmi <strong>TAK</strong>, ale nie oznacza to nic złego.</p>
<p>Kontrola nie zawsze jest powiązana z&nbsp;negatywnymi aspektami. 
Monitorowanie wyników osiąganych przez pracowników, ma na <strong>celu poprawę wydajności i rezultatów całej organizacji.</strong></p> 
<p>To, że szef prowadzi obserwację działań pracowników, nie oznacza ciągłego nadzoru nad zadaniami. 
Analizowanie informacji pozwala kierownictwu na poszukiwanie w przedsiębiorstwie elementów, które można zmienić, poprawić i&nbsp;udoskonalić.</p>
<p>Znasz już powód, dla którego <strong>program do tworzenia ofert handlowych</strong> jest ważnym narzędziem w&nbsp;działaniach kadry zarządzającej. 
Zastanawiasz się jednak, <strong>jakie korzyści system CRM przyniesie bezpośrednio Tobie?</strong></p>
<p>Teraz pojawi się jedno z ważniejszych pojęć definiujących Twoją pracę - <strong>TARGET.</strong></p>
<p>To właśnie w realizacji postawionych celów może Ci pomóc CRM dla handlowców. Jego zastosowanie wpłynie na lepszą organizację pracy, która z kolei przyczyni się do szybszego wypełniania założeń sprzedażowych.</p> 
<p>Mechanizm pozwala na bieżącą obserwację Twoich postępów. Unikasz dzięki temu problemów z oceną wyników swoich działań. 
Doskonale wiesz, w jakiej fazie realizacji się znajdujesz, a&nbsp;jeśli nie, to zawsze możesz sprawdzić to na pulpicie pracy w systemie. <strong>Widget “Moje cele” pomoże Ci w określeniu progresji oraz poziomu realizacji założeń.</strong></p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.panel]}
        alts={["Moje cele w programie do tworzenia ofert handlowych"]}
      /> 

 <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Program do tworzenia ofert handlowych to nie tylko oferty</strong>
      </h2>
<p>Program do tworzenia ofert handlowych jako system CRM służy nie tylko ofertowaniu. 
Jedną z&nbsp;jego możliwości jest budowanie nowych i&nbsp;podtrzymywanie obecnych relacji z klientami. 
Tworząc nowe więzi, maleje czas, który można poświęcić na te już istniejące.
</p>
<p>Rozwiązaniem tego problemu jest <strong>CRM dla handlowców</strong>, który pozwala na lepszą organizację pracy. 
Uporządkowanie zadań ma pozytywny wpływ na funkcjonowanie pracownika. 
Pojawia się więcej czasu, który można skierować na relacje z klientami.
</p>
<p>Dodatkowo, system CRM jest wsparciem pamięci. 
Znajdują się w nim wszystkie istotne dane o&nbsp;podejmowanych działaniach związanych z&nbsp;procesem sprzedaży. 
Dzięki odpowiednim powiadomieniom, program nie pozwoli zapomnieć pracownikowi o kluczowych wydarzeniach, które powinny się odbyć – od zaplanowanych maili, przez stworzenie oferty, aż po umówione spotkania z klientami.
</p>
<p><strong>Program do tworzenia ofert handlowych</strong> gromadzi także wszystkie dane historyczne - wcześniejsze ustalenia lub zaproponowane oferty. 
Każda uprawniona osoba ma dostęp do takich informacji. <strong>Służy to ciągłości obsługi klienta.</strong> W&nbsp;razie nieobecności pracownika, może zastąpić go ktoś inny i przejąć jego obowiązki.        
</p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz szybko tworzyć oferty dla Twoich klientów?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i przyśpieszyć proces ofertowania
            </Link>
          </div>
          </div>
    </section>
       <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Elementy karty klienta</strong>
      </h3>
<p>Karta klienta to baza wiedzy o Twoim kliencie. Poznasz dzięki niej podstawowe dane dotyczące klienta. Warto umieszczać w niej także trzy kluczowe informacje:
</p>
<p><ul><li>potrzeby klienta,</li>
<li>problemy klienta,</li>
<li>informacje prywatne dotyczące klienta.</li></ul>
</p>
<p>Zastanawiasz się pewnie, dlaczego zawierać kwestie niedotyczące biznesu w Karcie klienta?
</p> 
<p>Proste. Twoim celem jest budowanie relacji z&nbsp;klientami. 
Dobrym postępowaniem jest znajomość prywatnych kwestii, które możesz w miły sposób wykorzystać na początku rozmowy. 
Twój klient zobaczy, że jest dla Ciebie ważny.
</p>
        <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.notatka]}
        alts={["Karta klienta w programie do tworzenia ofert handlowych - notatka"]}
      />
<p>Korzystasz z systemu handlowo-magazynowego i&nbsp;obawiasz się utraty danych, 
które dotychczas zgromadziłeś? W przypadku naszego rozwiązania, problem ten nie istnieje. 
Dajemy Ci <strong>możliwość integracji Twojego systemu z programem do tworzenia ofert handlowych.</strong>
</p> 
<p>Takie połączenie powoduje, że dostęp do danych jest prostszy i szybszy, 
a cały proces sprzedaży uzupełniony jest o odpowiednią dokumentację. 
Masz dostęp do standardowych plików, którymi są oferty, zamówienia, faktury i płatności. 
Oprócz tego możesz obserwować również rozliczenia, zaległości i wyniki finansowe.
</p>
       <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.finanse]}
        alts={["Podgląd na finanse w programie do tworzenia ofert handlowych"]}
      />
<p>Zakładka <strong>Atrybuty</strong> może wydawać się tajemnicza – w rzeczywistości jednak to kolejna z kluczowych funkcji programu do tworzenia ofert handlowych, 
która umożliwia Ci <strong>dokładny opis klienta.</strong> Atrybuty można więc określić jako przymioty, które służą do szczegółowego określenia 
odbiorcy. Każdego ze swoich klientów możesz poznać lepiej poprzez zbieranie takich danych jak:
</p>
<p><ul><li>informacje dodatkowe,</li>
<li>branża,</li>
<li>produkt, którym jest zainteresowany,</li>
<li>wielkość firmy.</li></ul>
</p>
<p>Atrybuty możesz dobrać samodzielnie tak, by były pomocne w Twoich działaniach.
</p>
       <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.atrybuty]}
        alts={["Program do tworzenia ofert handlowych - dobór atrybutów"]}
      />
<p>Potrzebujesz sprawdzić, co ustaliłeś z jednym ze swoich klientów dwa tygodnie temu? 
Pamięć bywa zawodna. <strong>Z pomocą przychodzi program do tworzenia ofert handlowych.</strong>
</p>
<p>Zakładka <strong>Zadania i zdarzenia</strong> daje Ci dostęp do wszystkich aktywności, które odbyły się w&nbsp;powiązaniu z&nbsp;danym klientem w przeszłości. 
Nie musisz przetrzymywać tych danych w głowie, a&nbsp;swój notes z&nbsp;notatkami możesz pozostawić w&nbsp;domu.
</p>
 <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.karta]}
        alts={["Zadania i zdarzenia w karcie klienta - program do tworzenia ofert handlowych"]}
      />
<p><strong>Program do tworzenia ofert handlowych oferuje Ci wiele różnych integracji.</strong> Jedną z nich jest możliwość połączenia systemu CRM ze skrzynką pocztową. 
Dzięki temu oprócz działań powiązanych z klientem, możesz obserwować także korespondencję mailową, którą z nim prowadzisz. 
Dodatkowo, nowe komunikaty również mogą być tworzone bezpośrednio w&nbsp;systemie i wysyłane do odbiorców.
</p>
             <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
        }}
      ><strong>Program do tworzenia ofert handlowych, a automatyzacja pracy</strong>
      </h2>
          <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
        }}
      ><strong>Wiele możliwości i jeden cel – organizacja</strong>
      </h3>
<p><strong>Jednym z celów systemu CRM jest organizacja Twojej pracy</strong>, zadań, 
które masz do zrealizowania, spotkań i pozostałych kluczowych zdarzeń powiązanych z klientami.
</p>
<p>Zadania, które sobie planujesz, są automatycznie umieszczane w Kalendarzu. 
Rozwiązanie to daje Ci wygodny widok na plan Twojej pracy i rozkład jej w czasie.
</p>
<p>Możesz obserwować rozplanowanie zadań na bieżąco, ale jeśli o nich zapomnisz to 
Twój pomocnik – <strong>CRM dla handlowców</strong>, wesprze Cię i&nbsp;wyśle przypomnienie.
</p>
            <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.kalendarz]}
        alts={["Kalendarz w programie do tworzenia ofert handlowych"]}
      />
<p>Możliwości organizacji pracy jest w <strong>programie do tworzenia ofert handlowych znacznie więcej.</strong>
</p>
<p>Kalendarz może nie być pierwszą stroną, na którą trafisz po zalogowaniu. 
Miejscem tym jest <strong>Pulpit pracy</strong>, do którego możesz przypiąć wybrane widgety.
</p>
<p>W celu śledzenia zaplanowanych działań, możesz skorzystać z dwóch interesujących opcji.
Chcąc mieć widok na dany dzień, wybierz widget <strong>Moje zadania na dziś.</strong> Natomiast jeśli potrzebujesz większego zakresu czasu, skorzystaj z opcji <strong>Zadania i zdarzenia.</strong>
</p>
       <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.pulpit]}
        alts={["Mój pulpit w programie do tworzenia ofert handlowych"]}
      />
<p>Wolisz wersję mobilną? Mamy też aplikację na telefon. 
Daje Ci ona dostęp do listy Twoich zadań. Każde z&nbsp;zadań uzupełnione jest informacjami, 
które mogą Ci się przydać w momencie jego realizacji.
</p>
 
       <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Proste i szybkie ofertowanie z programem do tworzenia ofert handlowych</strong>
      </h3>
  <p>System CRM wspiera także obszar ofertowania. 
  Nie bez powodu nazywamy go <strong>programem do tworzenia ofert handlowych.</strong>
  </p>
  <p>Oferty dla potencjalnych klientów i zamówienia dla tych, 
  którzy są już zdecydowani nie przygotują się same. 
  Może to zrobić handlowiec, ale lepszym rozwiązaniem jest wykorzystanie systemu, 
  który potrzebuje tylko niewielkiego ludzkiego wsparcia.
  </p>
  <p>W systemie znajdują się nie tylko informacje o&nbsp;oferowanych produktach. 
  Te dane wzbogacone są o aktualne stany magazynowe oraz stosowane ceny.
</p>
<p>Powyższe sprawia, że <strong>praca handlowca w tym obszarze jest maksymalnie uproszczona.</strong> Wystarczy, że sprawdzi on odpowiednie informacje w systemie, a następnie wykorzysta je do automatycznego stworzenia oferty.
</p>
<p>Zadaniem pracownika jest tylko wybór odpowiedniego produktu. 
Może on także dokonać zmian cenowych, np.&nbsp;w przypadku innej opcji rabatowej ustalonej z klientem. 
Ta możliwość jest ważna, ponieważ co do zasady system uwzględnia rabat przypisany w Karcie klienta.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.dokument]}
        alts={["Program do tworzenia ofert handlowych - tworzenie ofery"]}
      />

      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.ofertowy]}
        alts={["Program do tworzenia ofert handlowych - tworzenie ofery - cd"]}
      />

<p>Działania możesz uprościć jeszcze bardziej. Możesz wykorzystać gotowy wzór oferty i&nbsp;wyeksportować w&nbsp;formacie PDF. 
</p>
<p>Sposób wysyłki oferty zależy od Ciebie. 
Jeśli posiadasz <strong>integrację programu do tworzenia ofert handlowych ze swoją skrzynką mailową</strong>, to możesz to zrobić z&nbsp;wykorzystaniem systemu. 
W&nbsp;innym przypadku Twoja praca się trochę wydłuży i&nbsp;będziesz musiał użyć innego narzędzia.
</p>
<p>Jeśli Twój klient zaakceptuje ofertę, możesz przeformować ją na zamówienie lub umowę. <strong>Oszczędność czasu i szybsza realizacja ustaleń. To zyskujesz.</strong>
</p>
<h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Czym są szanse sprzedaży?</strong>
      </h3>
<p>Chcesz zadbać o nowego potencjalnego klienta? Wykorzystaj moduł <strong>Szanse sprzedaży</strong>, który pozwoli Ci na przeprowadzenie klienta przez ustalone etapy procesu sprzedaży.
</p>
<p>Każdego klienta, który ma potencjał by stać się Twoim odbiorcą, 
możesz dodać w tym module jako nową szansę. Następnie możesz kontrolować postęp prac 
nad jego opieką, obserwować powiązane z nim zadania i&nbsp;inne kluczowe aspekty.
</p>
<p><strong>Widok wszystkich szans</strong> pokazuje Ci wszystkie Twoje szanse – aktualnie otwarte, wygrane i te przegrane. 
Masz możliwość analizy kwestii finansowych oraz możesz spojrzeć na aspekt terminowości.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.kanban]}
        alts={["Kanban w programie do tworzenia ofert handlowych"]}
      />
<p>Jeżeli potrzebujesz bardziej szczegółowych informacji o szansie, musisz otworzyć <strong>widok pojedyncza szansa.</strong>
</p>
<p>Takie działanie daje Ci wgląd w rozmowy przeprowadzone z klientem oraz oferty, które otrzymał. 
Program do tworzenia ofert handlowych sprawia także, że w widoku szans masz <strong>możliwość planowania zadań.</strong> Te z kolei są automatycznie wiązane z daną szansą. 
</p>
<p>Pojedyncza szansa to także dostęp do potencjalnej wartości transakcji i kluczowych terminów.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.szansa]}
        alts={["Pojedyncza szansa w programie do tworzenia ofert handlowych"]}
      />
<p>Szanse sprzedaży są odpowiedzią na pytanie, które zostało przedstawione we wstępie:
</p>
<p><strong>Czy program do tworzenia ofert handlowych może wesprzeć proces sprzedaży?</strong>
</p>
<p>System CRM, który zawiera moduł Szanse sprzedaży, <strong>ułatwia pracę handlowców.</strong> Proces sprzedaży, którym się oni zajmują jest udoskonalany, a sami przedstawiciele handlowi mogą sprawniej realizować swoje własne targety.
</p>
<h3
        style={{
          textAlign: "left",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      ><strong>Program do tworzenia ofert handlowych, a budowanie relacji?</strong>
      </h3>
<h4
        style={{
          textAlign: "left",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      ><strong>Komunikacja i integracja ze współpracownikami</strong>
      </h4>
<p><strong>System CRM</strong> posiada wbudowany komunikator, który możesz wykorzystać do wymiany informacji biznesowych z osobami, które pracują w tym samym miejscu co Ty.
</p>
<p>Czat sprawia także, że łatwiej tworzyć relacje prywatne ze współpracownikami. 
Chciałbyś wybrać się na spotkanie po pracy? 
Wyślij wiadomość do wybranej osoby, grupy lub wszystkich pracowników.
</p>
<p>Możesz powiązać wiadomość z&nbsp;jakąś kluczową informacją, 
która znajduje się w&nbsp;innym miejscu w&nbsp;systemie. 
Wystarczy, że swój komunikat stworzysz właśnie w&nbsp;tym miejscu, a&nbsp;nie w&nbsp;komunikatorze.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.komunikator]}
        alts={["Program do tworzenia ofert handlowych - komunikator"]}
      />
<h4
        style={{
          textAlign: "left",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      ><strong>Efektywne dbanie o relacje z klientami</strong>
      </h4>
<p>Program do tworzenia ofert handlowych jest wzbogacony o możliwość korzystania z mapy. 
Funkcja ta pokazuje rozkład Twoich klientów na określonym obszarze.
</p>
<p><strong>Dlaczego warto korzystać z tej opcji?</strong>
</p>
<p>Wyobraź sobie, że wybierasz się na spotkanie z&nbsp;klientem do innego miasta. 
Dzięki mapie możesz sprawdzić innych klientów, którzy znajdują się w&nbsp;danym regionie. 
Może warto o&nbsp;nich zadbać i&nbsp;ich odwiedzić?
</p>
<p>Takie działanie może sprawić, że potencjalny klient zdecyduje się na współpracę, a Twój obecny odbiorca będzie chciał kontynuować swoją drogę razem z Tobą.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.mapa]}
        alts={["Progra do tworzenia ofert handlowych - mapa"]}
      />

      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Podsumowanie</strong>
      </h2>

  <p><strong>Program do tworzenia ofert handlowych może pomóc Ci nie tylko w ofertowaniu.</strong> Dzięki niemu zadbasz o relacje ze swoimi klientami oraz współpracownikami, 
  a także usprawnisz i&nbsp;zorganizujesz swoją pracę.
  </p>
  <p>Jeśli chcesz wypróbować CRM w swojej firmie, skontaktuj się z nami. 
  Chętnie pomożemy Ci w&nbsp;początku współpracy z systemem CRM.
  </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz szybko tworzyć oferty dla Twoich klientów?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i przyśpieszyć proces ofertowania
            </Link>
          </div>
          </div>
    </section>

       <p><strong>Polecamy również:</strong>
        <ul>
          <li>
            <Link to="/lejek-sprzedazy-crm/">Lejek sprzedaży - wsparcie dla działu handlowego
</Link>
          </li>
          <li>
            <Link to="/zalety-elastycznego-crm/">
              {" "}
Zalety elastycznego systemu CRM
            </Link>
          </li>
          <li>
            <Link to="/integracja-crm-z-systemem-handlowym/">
              {" "}
             Integracja CRM z systemem handlowym
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default programdotworzeniaoferthandlowych;
